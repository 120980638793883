import {
    faAngleDown,
    faArrowDown,
    faArrowUp,
    faHeart,
    faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef } from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy } from "react-table";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
    faAngleUp,
    faCaretDown,
    faCaretUp,
    faSearch,
    faQuestionCircle,
    faExclamationCircle,
    faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import { openModal } from "../../../../app/common/modals/modalReducer";
import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import ErrorBoundary from "../../../../app/layout/ErrorBoundary";

export default function ShopPage(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const inputRefs = new Map();

    // console.log(currentUser)

    // let pathname = props.location.pathname;
    // let newPathname = pathname.replace("/", "");
    // let pageName = newPathname.replaceAll("-", " ");

    let pathname = props.location.pathname;
    let newPathname = pathname.replace(/\//g, "");
    let pageName = newPathname.replace(/-/g, " ");

    const [cartTotal, setCartTotal] = useState([]);
    const [products, setProducts] = useState([]);
    const [SearchValue, setSearchValue] = useState(props.location.state);
    // const [suppliers, setSuppliers] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSupplier, setSelectedSupplier] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [recordCount, setRecordCount] = useState("0");
    const [sortBy, setSortBy] = useState("AltStockCode");
    const [sortDirection, setSortDirection] = useState("desc");
    const [pageSizeValue, setPageSizeValue] = useState("10");
    const [brands, setBrands] = useState([]);
    const [imgActive, setImgActive] = useState();
    const [stockActive, setStockActive] = useState();
    const [descActive, setDescActive] = useState();
    const [barActive, setBarActive] = useState();
    const [petBrandsActive, setPetBrandsActive] = useState();
    const [displaySettings, setDisplaySettings] = useState(false);
    const [addedProd, setAddedProd] = useState(false);
    const [heightStyle, setHeightStyle] = useState("searchheightLg");
    const [displayAdvanced, setDisplayAdvanced] = useState(true);
    const [onHoldLoading, setOnHoldLoading] = useState(true);
    const [productCategories, setProductCategories] = useState([]);

    //USe effect to get product categories from the server
    useEffect(() => {
        if (productCategories.length === 0) {
            getProductCategories();
        }
    }, []);

    async function getProductCategories() {
        let productCategories = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get_product_categories`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
        });

        setProductCategories(productCategories.data);
    }

    window.addEventListener("resize", handleResize);

    const allowedSups =
        currentUser.supplier_stock != null
            ? currentUser.supplier_stock.split(",").map(function (item) {
                  return item.trim();
              })
            : currentUser.supplier_stock;

    if (
        currentUser.multi_account === true &&
        (currentUser.customer_code == null ||
            currentUser.customer_code == "None" ||
            currentUser.customer_code.split(",").length > 1)
    ) {
        window.location.replace("/select-profile");
    }

    function handleResize() {
        if (window.innerWidth > 1023) {
            setDisplayAdvanced(true);
        }

        if (window.innerHeight > 800) {
            setHeightStyle("searchheightLg");
        } else if (window.innerHeight < 801 && window.innerHeight > 650) {
            setHeightStyle("searchheightMd");
        } else {
            setHeightStyle("searchheight");
        }
    }

    function changeAdvancedView(value) {
        if (window.innerWidth < 1023) {
            value == false
                ? setDisplayAdvanced(true)
                : setDisplayAdvanced(false);
        }
    }

    let searchStyle = "py-2 px-2 text-sm cursor-pointer";
    let searchStyleActive =
        "py-2 px-2 text-sm cursor-pointer text-white bg-gray-500";

    let timeUpdated = window.sessionStorage.getItem("displayUpdated");
    let updating = false;

    // setInterval(async () => {
    //   let newTimeUpdated = window.sessionStorage.getItem("displayUpdated");
    //   if(newTimeUpdated !== timeUpdated && updating == false){
    //         updating = true
    //         console.log(updating)
    //         updating = false
    // //     console.log(newTimeUpdated, timeUpdated)
    // //     timeUpdated = window.sessionStorage.getItem("displayUpdated");
    // //     await getProducts(pageNumber, pageSizeValue, selectedCategory);
    // //     console.log("here")
    //   }
    // }, 1000)

    useEffect(() => {
        window.addEventListener("storage", (e) => {
            if (e.key == "displayUpdatedDate") {
                getProducts(pageNumber, pageSizeValue, selectedCategory);
            }
        });

        setLoading(true);
        getProducts(pageNumber, pageSizeValue, selectedCategory);
        // setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        // getSuppliers();

        setAddedProd(false);

        // console.log('useEffect', pageNumber, pageSizeValue, selectedCategory);
    }, [
        selectedCategory,
        selectedSupplier,
        pageNumber,
        sortBy,
        sortDirection,
        addedProd,
    ]);

    if (currentUser.CustomerOnHold === "Y" && onHoldLoading === true) {
        setOnHoldLoading(false);
        dispatch(
            openModal({
                modalType: "OnHoldModal",
                modalProps: { type: "page" },
            })
        );
    }

    // async function getSuppliers(){
    //   let suppliersData = await axios({
    //       method: "get",
    //       url: `${process.env.REACT_APP_API_URL}all-suppliers`,
    //       headers: { Authorization: `Bearer ${currentUser.token}` },
    //   });

    //   setSuppliers(suppliersData.data)
    // }

    async function getProducts(pageNo, pageSizeValue, selectedCategoryInfo) {
        let dispalyView = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-view-display`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { userId: currentUser.id },
        });

        if (dispalyView.data.display_img == 0) {
            setImgActive(false);
        } else {
            setImgActive(true);
        }

        if (dispalyView.data.display_code == 0) {
            setStockActive(false);
        } else {
            setStockActive(true);
        }

        if (dispalyView.data.display_desc == 0) {
            setDescActive(false);
        } else {
            setDescActive(true);
        }

        if (dispalyView.data.display_barCode == 0) {
            setBarActive(false);
        } else {
            setBarActive(true);
        }

        if (dispalyView.data.display_petBrands == 0) {
            setPetBrandsActive(false);
        } else {
            setPetBrandsActive(true);
        }

        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
            },
        });

        let favData = favouritesResults.data[0];
        let favIds = [];

        // console.log("Code", currentUser.branch)

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id);
        });
        let productResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-products-new`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                category: selectedCategoryInfo,
                supplier: selectedSupplier,
                SearchValue: SearchValue,
                type: "search",
                pageNumber: pageNo,
                sortBy: sortBy,
                sortDirection: sortDirection,
                branch: currentUser.branch,
                pageSizeValue: pageSizeValue,
                allowed_supplier: currentUser.allowed_supplier,
            },
        });

        let prodData = productResults.data[0];
        let prodDataSubCode = productResults.data[4];
        let prodDataInCart = productResults.data[2];
        let prodDataSpecial = productResults.data[3];
        setRecordCount(productResults.data[1][0][""]);
        let allproducts = [];
        // let totalItems = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));

        let brandsResult = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}brands`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                branch: currentUser.branch,
            },
        });

        let brandNames = brandsResult.data;
        brandNames.sort((a, b) =>
            a.AlternateKey1.localeCompare(b.AlternateKey1)
        );
        //   console.log(brandNames)
        //420 brnads

        // brandsResult

        // await Object.keys(prodData).forEach(function (key) {

        //   let total = "0";
        //   Object.keys(totalItems).forEach(function (index) {
        //       if(totalItems[index][1].trim() === prodData[key].StockCode.trim()){
        //           total = totalItems[index][0];
        //       }
        //   });

        //   if(favIds.includes((prodData[key].StockCode).trim())){
        //     allproducts.push({col0: prodData[key].StockCode, col1: prodData[key].col1, col2: prodData[key].StockCode, col3: prodData[key].Description, col4: prodData[key].InvWarehouse, col5: prodData[key].InvPrice[0] ? "R " + prodData[key].InvPrice[0].SellingPrice : "Free", col6: total ,col7: true})
        //   }
        // })

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code);
        });

        await Object.keys(prodData).forEach(async function (key) {
            let newStockCode = prodData[key].StockCode;
            newStockCode = newStockCode.replace(" ", "");

            // console.log(prodDataSubCode.length > 0)
            //     console.log(prodData[key].StockCode)

            let display = "";

            if (
                prodDataSubCode.length > 0 &&
                allCodes.includes(prodData[key].StockCode)
            ) {
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == prodData[key].StockCode) {
                        display = subCode.custom_code;
                    }
                });
            }

            if (newStockCode) {
                let curfav = false;

                if (favIds.includes(prodData[key].StockCode)) {
                    curfav = true;
                }

                let total = "0";
                let inCart = "no";

                await Object.keys(prodDataInCart).forEach(function (index) {
                    if (
                        prodData[key].StockCode ===
                        prodDataInCart[index].stock_code
                    ) {
                        total = prodDataInCart[index].qty;
                        inCart = "yes";
                    }
                });

                let promoId = [];
                let promoArr = [];

                prodDataSpecial.forEach(function (promo) {
                    let items = promo.items;
                    let onPromo = [];
                    items.forEach(function (item) {
                        if (
                            item.product_type == "1" &&
                            prodData[key].StockCode == item.product_stock_code
                        ) {
                            onPromo.push("yes");
                        }
                    });

                    onPromo.forEach(function (itemsPromo) {
                        if (itemsPromo !== undefined) {
                            promoArr.push(onPromo[0]);
                            if (onPromo[0] == "yes") {
                                promoId.push(promo.id);
                            }
                        }
                    });
                });

                // await Object.keys(prodDataSpecial).forEach(async function (index2) {
                //   // console.log(prodDataSpecial[index2])
                //   if(prodDataSpecial[key] && prodDataSpecial[key].items){
                //     //
                //     let items = prodDataSpecial[key].items
                //     await Object.keys(items).forEach(function (indexItem) {
                //       if(items[indexItem].product_type == '1' && prodData[key].StockCode === items[indexItem].product_stock_code){
                //         promo = "yes";
                //         promoId = prodDataSpecial[key].id
                //       }
                //     })
                //   }
                // })

                // console.log("1")

                // if (totalItems) {
                //     Object.keys(totalItems).forEach(function (index) {
                //         if(totalItems[index][1] === prodData[key].StockCode){
                //             total = totalItems[index][0];
                //         }
                //     });
                // }

                const someTimeoutAction2 = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve({
                                col0: prodData[key].col0,
                                col1: prodData[key].col1,
                                col2: prodData[key].StockCode,
                                col3: prodData[key].Description,
                                col4: prodData[key].InvWarehouse,
                                col5: prodData[key].SellingPrice
                                    ? "R " +
                                      parseFloat(
                                          prodData[key].SellingPrice
                                      ).toFixed(2)
                                    : "Free",
                                col6: total,
                                col7: curfav,
                                col8: prodData[key].LongDesc,
                                col9: prodData[key].AltStockCode,
                                col10: prodData[key].ProductGroup,
                                col11: prodData[key].AlternateUom,
                                col12: promoArr[0],
                                col13: promoId[0],
                                col14: inCart,
                                col15: selectedCategoryInfo,
                                col16: prodData[key].altInStock,
                                col17: prodData[key].BarCode,
                                col18:
                                    prodData[key].special_prices !== "" &&
                                    prodData[key].special_prices !== null
                                        ? "R " +
                                          parseFloat(
                                              prodData[key].special_prices
                                          ).toFixed(2)
                                        : "",
                                col19: display,
                                col20: prodData[key].img,
                                col23: prodData[key].UserField1,
                                col24: prodData[key].AlternateKey2,
                                col25: prodData[key].InvwarehouseQty,
                                col26: prodData[key].Supplier,
                                col27:
                                    prodData[key].warehouse99 > 0
                                        ? parseFloat(
                                              prodData[key].warehouse99
                                          ).toFixed(2)
                                        : 0,
                            });
                        }, 500);
                    });
                };

                const prods = await someTimeoutAction2();

                allproducts.push(prods);
            }
        });

        const someTimeoutAction2 = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(allproducts);
                }, 600);
            });
        };

        const prods2 = await someTimeoutAction2();

        setProducts(prods2);
        setLoading(false);
    }

    async function nextPageNavigate(
        pageNumber,
        pageSizeValue,
        selectedCategoryInfo
    ) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo + 1;

        setPageNumber(newNo);

        getProducts(newNo, pageSizeValue, selectedCategoryInfo);
    }

    async function previousPageNavigate(
        pageNumber,
        pageSizeValue,
        selectedCategoryInfo
    ) {
        let pageNo = parseFloat(pageNumber);
        let newNo = pageNo - 1;

        setPageNumber(newNo);

        getProducts(newNo, pageSizeValue, selectedCategoryInfo);
    }

    // let breadcrumbs = [
    //     { name: "Home", href: "/", count: 1 },
    //     { name: "Shop", href: "/shop", count: 2 },
    //     { name: pageName, href: pathname, count: 3 },
    // ];

    async function addFavourite(rowId, pageNumber, pageSizeValue, category) {
        // console.log(rowId)
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts(pageNumber, pageSizeValue, category);
    }

    async function removeFavourite(rowId, pageNumber, pageSizeValue, category) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        getProducts(pageNumber, pageSizeValue, category);
    }

    async function addItemToCart(item, term) {
        const qty = inputRefs.get(item.col2).current.value;
        const itemId = item.col2.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        // if (cartItem.data == 1) {
        //     let CurrentCartAmount =
        //         window.sessionStorage.getItem("itemsInBasket");
        //     let NewAmount = parseFloat(CurrentCartAmount) + 1;
        //     window.sessionStorage.setItem("itemsInBasket", NewAmount);
        // }

        toast.success("Item Added to Cart", {
            position: "bottom-right",
            autoClose: 5000,
            closeOnClick: true,
        });

        setAddedProd(true);
    }

    async function setTotals(totalAmount, itemId, category) {
        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });
    }

    async function changePageSize(value, category) {
        setPageSizeValue(value);
        getProducts(0, value, category);
    }

    async function openPromo(value) {
        let activeSpecial = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-details`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { promotionID: value },
        });

        let specialData = [activeSpecial.data];
        let specialInfo = [];

        await Object.keys(specialData).forEach(async function (key) {
            let loopItems = specialData[key].items;
            let products = [];
            let free = [];

            await Object.keys(loopItems).forEach(async function (index) {
                if (loopItems[index].product_type == "1") {
                    products.push(loopItems[index].product_stock_code);
                }
                if (loopItems[index].product_type == "2") {
                    free.push(loopItems[index].product_stock_code);
                }
            });

            specialInfo.push({
                col0: specialData[key].id,
                col1: specialData[key].promotion_name,
                col2: specialData[key].from_date,
                col3: specialData[key].to_date,
                col4: specialData[key].discount_type,
                col5: specialData[key].free_amount,
                col6: specialData[key].discount_percentage,
                col7: specialData[key].special_type,
                col8: specialData[key].req_buy_amount,
                col9: specialData[key].free_product_type,
                col9: specialData[key].limit_on_order,
                col10: specialData[key].limit_per_day,
                col11: products,
                col12: free,
                col13: specialData[key].special_on,
                col14: specialData[key].ProductsGroup,
                col15: specialData[key].rules,
                col16: specialData[key].file_url,
                col17: specialData[key].promo_desc,
            });
        });

        dispatch(
            openModal({
                modalType:
                    activeSpecial.data.discount_type == "1"
                        ? "PromotionDiscount"
                        : "PromotionFree",
                modalProps: { Promotion: specialInfo[0] },
            })
        );
    }

    const data = React.useMemo(() => products, [products]);

    let imgCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Image
            </div>
        ),
        accessor: "col1",
        Cell: (tableProps) => (
            <>
                <img
                    src={
                        tableProps.row.original.col20 !== ""
                            ? process.env.REACT_APP_URL +
                              "media/" +
                              tableProps.row.original.col20
                            : process.env.REACT_APP_URL +
                              "/images/placeholder-image.png"
                    }
                    width={150}
                    alt={tableProps.row.original.col3}
                    className="mx-auto px-5 py-5"
                />
            </>
        ),
    };

    let barCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Barcode
            </div>
        ),
        accessor: "col17",
        Cell: (tableProps) => <>{tableProps.row.original.col17}</>,
    };

    let categoryCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Pet Brands
            </div>
        ),
        accessor: "col23",
        Cell: (rowInfo) => {
            return (
                <>
                    {rowInfo.row.original.col23 !== ""
                        ? rowInfo.row.original.col23 +
                          " - " +
                          rowInfo.row.original.col24
                        : rowInfo.row.original.col23 +
                          " - " +
                          rowInfo.row.original.col24}
                </>
            );
        },
    };

    let codeCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
                id="StockCode"
                className="cursor-pointer flex"
            >
                <span
                    onClick={() => {
                        setSortBy("StockCode");
                        "StockCode" == sortBy && sortDirection == "asc"
                            ? setSortDirection("desc")
                            : setSortDirection("asc");
                    }}
                >
                    Code
                </span>

                {sortBy === "StockCode" && sortDirection === "asc" ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className={
                            sortBy === "StockCode"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("StockCode");
                            "StockCode" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={
                            sortBy === "StockCode"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("StockCode");
                            "StockCode" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                )}
                {sortBy === "StockCode" && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400 ml-5 self-center cursor-pointer"
                        onClick={() => {
                            setSortBy("AltStockCode");
                            setSortDirection("desc");
                        }}
                    />
                )}
            </div>
        ),
        accessor: "col2",
        Cell: (rowInfo) => {
            return (
                <>
                    {rowInfo.row.original.col19 !== ""
                        ? rowInfo.row.original.col19
                        : rowInfo.row.original.col2}
                </>
            );
        },
    };

    let descCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
                id="Description"
                className="cursor-pointer flex"
            >
                <span
                    onClick={() => {
                        setSortBy("Description");
                        "Description" == sortBy && sortDirection == "asc"
                            ? setSortDirection("desc")
                            : setSortDirection("asc");
                    }}
                >
                    Description
                </span>

                {sortBy === "Description" && sortDirection === "asc" ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className={
                            sortBy === "Description"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("Description");
                            "Description" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={
                            sortBy === "Description"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("Description");
                            "Description" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                )}

                {sortBy === "Description" && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400 ml-5 self-center cursor-pointer"
                        onClick={() => {
                            setSortBy("AltStockCode");
                            setSortDirection("desc");
                        }}
                    />
                )}
            </div>
        ),
        accessor: "col3",
        Cell: (rowInfo) => {
            return (
                <>
                    <p>{rowInfo.row.original.col3}</p>
                    <span>{rowInfo.row.original.col8}</span> &nbsp;&nbsp;
                    <>
                        {rowInfo.row.original.col10 == "PG008" ||
                        rowInfo.row.original.col10 == "PG003" ||
                        rowInfo.row.original.col10 == "PG007" ||
                        rowInfo.row.original.col10 == "PG001" ? (
                            <>
                                {/* <br/>  */}
                                {rowInfo.row.original.col10 == "PG008" ? (
                                    <>
                                        <span
                                            data-tip="NON-RETURNABLE. This is a product registered under the Medicines and Related Substance Act 101 of 1965 as Schedule 6 and requires a prescription to be submitted to Lakato. This product is non-returnable"
                                            className="text-red-500"
                                        >
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}

                                {rowInfo.row.original.col10 == "PG003" ||
                                rowInfo.row.original.col10 == "PG007" ? (
                                    <>
                                        <span
                                            data-tip="NON-RETURNABLE. This is a cold-chain product and is non-returnable"
                                            className="text-red-500"
                                        >
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />{" "}
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}

                                {rowInfo.row.original.col10 == "PG001" ? (
                                    <>
                                        <span
                                            data-tip="NON-RETURNABLE. This product registered under the Medicines and Related Substance Act 101 of 1965 and is non-returnable"
                                            className="text-red-500"
                                        >
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />{" "}
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </>
                </>
            );
        },
    };

    let handCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
                id="QtyOnHand"
                className="cursor-pointer flex"
            >
                <span
                    onClick={() => {
                        setSortBy("QtyOnHand");
                        "QtyOnHand" == sortBy && sortDirection == "asc"
                            ? setSortDirection("desc")
                            : setSortDirection("asc");
                    }}
                >
                    Stock Status
                </span>

                {sortBy === "QtyOnHand" && sortDirection === "asc" ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className={
                            sortBy === "QtyOnHand"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("QtyOnHand");
                            "QtyOnHand" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={
                            sortBy === "QtyOnHand"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("QtyOnHand");
                            "QtyOnHand" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                )}
                {sortBy === "QtyOnHand" && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400 ml-5 self-center cursor-pointer"
                        onClick={() => {
                            setSortBy("AltStockCode");
                            setSortDirection("desc");
                        }}
                    />
                )}
            </div>
        ),
        accessor: "col4",
        Cell: (rowInfo) => {
            return (
                <>
                    <p>
                        {rowInfo.row.original.col4 === "Out of Stock" ? (
                            <>
                                {rowInfo.row.original.col9 !== null &&
                                rowInfo.row.original.col16 !== 0 ? (
                                    <>
                                        <button
                                            className="px-2 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modalType:
                                                            "Alternative",
                                                        modalProps: {
                                                            stockCode:
                                                                rowInfo.row
                                                                    .original
                                                                    .col2,
                                                        },
                                                    })
                                                )
                                            }
                                        >
                                            See Alternative
                                        </button>
                                    </>
                                ) : (
                                    <p className="text-red-500">
                                        {rowInfo.row.original.col4}
                                        {currentUser.see_stock === 1 &&
                                        currentUser.supplier_stock !== null &&
                                        allowedSups.includes(
                                            rowInfo.row.original.col26
                                        ) ? (
                                            <>
                                                {"( "}
                                                {rowInfo.row.original.col25}
                                                {" )"}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </p>
                                )}
                            </>
                        ) : (
                            <>
                                <p>
                                    {rowInfo.row.original.col4}{" "}
                                    {currentUser.see_stock === 1 &&
                                    currentUser.supplier_stock !== null &&
                                    allowedSups.includes(
                                        rowInfo.row.original.col26
                                    ) ? (
                                        <>
                                            {"( "}
                                            {rowInfo.row.original.col25}
                                            {" )"}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </p>
                                {rowInfo.row.original.col12 === "yes" ? (
                                    <>
                                        <br />
                                        <p
                                            className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer"
                                            onClick={() =>
                                                openPromo(
                                                    rowInfo.row.original.col13
                                                )
                                            }
                                        >
                                            On Promotion !
                                        </p>
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        <div>
                            {currentUser.warehouse99 == 1 &&
                                "Bulk ( " +
                                    parseFloat(
                                        rowInfo.row.original.col27
                                    ).toFixed(0) +
                                    " )"}
                        </div>
                    </p>
                </>
            );
        },
    };

    let priceCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
                id="SellingPrice"
                className="cursor-pointer flex"
            >
                <span
                    onClick={() => {
                        setSortBy("SellingPrice");
                        "SellingPrice" == sortBy && sortDirection == "asc"
                            ? setSortDirection("desc")
                            : setSortDirection("asc");
                    }}
                >
                    Price Excl. VAT
                </span>

                {sortBy === "SellingPrice" && sortDirection === "asc" ? (
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        className={
                            sortBy === "SellingPrice"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("SellingPrice");
                            "SellingPrice" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faCaretDown}
                        className={
                            sortBy === "SellingPrice"
                                ? "text-blue-600 ml-5 self-center"
                                : "text-gray-400 ml-5 self-center"
                        }
                        onClick={() => {
                            setSortBy("SellingPrice");
                            "SellingPrice" == sortBy && sortDirection == "asc"
                                ? setSortDirection("desc")
                                : setSortDirection("asc");
                        }}
                    />
                )}
                {sortBy === "SellingPrice" && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-red-400 ml-5 self-center cursor-pointer"
                        onClick={() => {
                            setSortBy("AltStockCode");
                            setSortDirection("desc");
                        }}
                    />
                )}
            </div>
        ),
        accessor: "col5",
        Cell: (rowInfo) => {
            return (
                <>
                    <p>
                        {rowInfo.row.original.col18 !== "" &&
                        rowInfo.row.original.col18 !== null &&
                        parseFloat(
                            rowInfo.row.original.col18.replace("R ", "")
                        ) <
                            parseFloat(
                                rowInfo.row.original.col5.replace("R ", "")
                            ) ? (
                            <>
                                <del>{rowInfo.row.original.col5}</del>
                                <br />
                                {rowInfo.row.original.col18}
                            </>
                        ) : (
                            <>{rowInfo.row.original.col5}</>
                        )}

                        {rowInfo.row.original.col11 == "SD" ? (
                            <>
                                <span
                                    className="text-green-500 ml-2"
                                    data-tip="Settlement Discount if Applicable"
                                >
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                    />
                                </span>{" "}
                                <ReactTooltip />
                            </>
                        ) : (
                            ""
                        )}
                    </p>
                </>
            );
        },
    };

    let qtyCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                QTY
            </div>
        ),
        accessor: "col6",
        Cell: (rowInfo) => {
            const inputRef = createRef();
            inputRefs.set(rowInfo.row.original.col2, inputRef);

            return (
                <div key={rowInfo.row.original.col2}>
                    <input
                        ref={inputRef}
                        type="number"
                        min={rowInfo.row.original.col14 == "yes" ? "1" : "0"}
                        defaultValue={rowInfo.row.original.col6}
                        className="shadow-sm text-sm border border_radius_5 w-20"
                        onChange={(values) => {
                            // let value = Math.abs(parseFloat(values.target.value));
                            // setTotals(
                            //     value,
                            //     rowInfo.row.original.col2,
                            //     rowInfo.row.original.col14,
                            //     rowInfo.row.original.col15
                            // );
                            // values.target.value = value;
                            // console.log("test", rowInfo.row.original.col15);
                            // let mintot =
                            //     rowInfo.row.original.col14 == "yes" ? 1 : 0;
                            // parseFloat(values.target.value) < mintot
                            //     ? setTotals(
                            //         mintot,
                            //         rowInfo.row.original.col2,
                            //         rowInfo.row.original.col15
                            //     )
                            //     : setTotals(
                            //         values.target.value,
                            //         rowInfo.row.original.col2,
                            //         rowInfo.row.original.col15
                            //     );
                            // if (values.target.value == 0) {
                            //     values.target.value = mintot;
                            // } else {
                            //     values.target.value = Math.abs(
                            //         values.target.value
                            //     );
                            // }
                        }}
                    />
                    {/* )} */}
                </div>
            );
        },
    };

    let cartCol = {
        Header: () => (
            <div style={{ textAlign: "left" }}>
                {/* {sortBy !== "AltStockCode" && (
            <FontAwesomeIcon 
                  icon={faTimes} 
                  className="text-red-400 ml-5 self-center cursor-pointer"
                  onClick={() => {setSortBy('AltStockCode'); setSortDirection('desc');}}
                  /> 
            )} */}
            </div>
        ),
        accessor: "col7",
        Cell: (rowInfo) => {
            return (
                <div className="flex">
                    {rowInfo.row.original.col7 === true ? (
                        <FontAwesomeIcon
                            icon={solidHeart}
                            className="absolute top-3 right-1 text-xl text-gray-500 cursor-pointer mr-1"
                            onClick={() =>
                                removeFavourite(
                                    rowInfo.row.original.col2,
                                    pageNumber,
                                    pageSizeValue,
                                    rowInfo.row.original.col15
                                )
                            }
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faHeart}
                            className="absolute top-3 right-1 text-xl text-gray-500 cursor-pointer mr-1"
                            onClick={() =>
                                addFavourite(
                                    rowInfo.row.original.col2,
                                    pageNumber,
                                    pageSizeValue,
                                    rowInfo.row.original.col15
                                )
                            }
                        />
                    )}

                    {/* {rowInfo.row.original.col4 === "Out of Stock" ? (
                        <></>
                    ) : ( */}
                    <>
                        <button
                            className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex"
                            onClick={() =>
                                addItemToCart(rowInfo.row.original, "")
                            }
                        >
                            {/* Add to cart */}
                            {/* + <FontAwesomeIcon icon={faShoppingCart}/> */}
                            <span className="pr-2 mt-1">+</span>{" "}
                            <FontAwesomeIcon
                                icon={faShoppingCart}
                                className="mt-2"
                            />
                        </button>
                    </>
                    {/* )} */}

                    {rowInfo.row.original.col14 == "yes" ? (
                        <span
                            className="mt-7 bg-red-500 h-6 px-2 py-1 rounded-full text-white min-w-min_red_btn flex justify-center"
                            data-tip={
                                rowInfo.row.original.col6 +
                                " of this item is in your cart"
                            }
                        >
                            {rowInfo.row.original.col6} <ReactTooltip />
                        </span>
                    ) : (
                        <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                    )}
                </div>
            );
        },
    };

    let all = [];

    if (imgActive === true) {
        all.push(imgCol);
    }
    if (stockActive === true) {
        all.push(codeCol);
    }
    if (barActive === true) {
        all.push(barCol);
    }
    if (descActive === true) {
        all.push(descCol);
    }
    // if(petBrandsActive === true){
    //     all.push(categoryCol);
    // }

    all.push(handCol);
    all.push(priceCol);
    all.push(qtyCol);
    all.push(cartCol);

    const columns = React.useMemo(
        () => all,
        [
            sortBy,
            sortDirection,
            imgActive,
            stockActive,
            descActive,
            barActive,
            petBrandsActive,
        ]
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        setPageSize,
    } = tableInstance;

    async function updateViewValue(UpdateField, updateValue) {
        if (updateValue == true) {
            var valueToUpdate = 1;
        } else {
            var valueToUpdate = 0;
        }

        let UpdateView = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}set-view-display`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                userId: currentUser.id,
                field: UpdateField,
                value: valueToUpdate,
            },
        });
    }

    return (
        <div className="bg-gray-50">
            {/* <div className="w-full mx-auto bg-gradient-to-b from-gray-300"> */}
            <div className="w-full mx-auto ">
                <AdminSearchForm
                    heading={pageName}
                    color="text-gray-500"
                    header_color="bg-gradient-to-b from-gray-300"
                    crumb_color="text-black"
                    breadcrumbs={[
                        { name: "Home", href: "/", count: 1 },
                        { name: "Shop", href: "/shop", count: 2 },
                    ]}
                />
                <br />
            </div>

            <ToastContainer />

            <div className="container mx-auto grid place-content-end">
                {/* {displaySettings == false && (
            <button
                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                onClick={() =>
                  setDisplaySettings(true)
                }
            >
                Display Settings
            </button>
          )}
          {displaySettings == true && (
          <div className="bg-gray-100 px-5 py-5">
            <p><u>Display Columns:</u> &nbsp;&nbsp;
              <input type="checkbox" value="img" name="img" defaultChecked={imgActive} onClick={(value) => {setImgActive(value.target.checked); updateViewValue('display_img', value.target.checked)}}/> Image &nbsp;&nbsp;
              <input type="checkbox" value="stock" name="stock" defaultChecked={stockActive} onClick={(value) => {setStockActive(value.target.checked); updateViewValue('display_code', value.target.checked)}}/> Stock Code &nbsp;&nbsp;
              <input type="checkbox" value="barCode" name="barCode" defaultChecked={barActive} onClick={(value) => {setBarActive(value.target.checked); updateViewValue('display_barCode', value.target.checked)}}/> BarCode &nbsp; &nbsp;
              <input type="checkbox" value="desc" name="desc" defaultChecked={descActive} onClick={(value) => {setDescActive(value.target.checked); updateViewValue('display_desc', value.target.checked)}}/> Description 
              <FontAwesomeIcon icon={faTimes} onClick={() => setDisplaySettings(false)} className="text-red-500 ml-5 self-center text-xl cursor-pointer"/>
              
            </p>
          </div>
          )} */}
            </div>

            <div className="container mx-auto flex flex-col lg:flex-row">
                {/* categoryForSearch */}

                <div className="lg:w-1/5 md:w-full px-5 mt-4 sm:w-full">
                    <div
                        className={`sticky top-44 lg:h-[75vh] lg:overflow-auto sm:cursor-pointer md:cursor-pointer`}
                    >
                        <div
                            className="px-2 py-2 bg-gray-200 sticky top-0"
                            onClick={() => changeAdvancedView(displayAdvanced)}
                        >
                            <strong>
                                {" "}
                                {displayAdvanced === false && (
                                    <FontAwesomeIcon
                                        icon={faArrowDown}
                                        className="mr-5 lg:hidden"
                                    />
                                )}{" "}
                                {displayAdvanced === true && (
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className="mr-5 lg:hidden"
                                    />
                                )}{" "}
                                Advanced Search
                            </strong>
                        </div>
                        {displayAdvanced === true && (
                            <div className="divide-y divide-slate-200 bg-white">
                                <div
                                    className={
                                        selectedCategory == ""
                                            ? searchStyleActive
                                            : searchStyle
                                    }
                                    onClick={() => {
                                        setSelectedCategory("");
                                        window.sessionStorage.setItem(
                                            "categoryForSearch",
                                            ""
                                        );
                                    }}
                                >
                                    All Categories
                                </div>
                                {productCategories
                                    .filter((pc) => pc.parent_category_id == 0)
                                    .map((category) => (
                                        <div key={category.id}>
                                            <div
                                                className={
                                                    selectedCategory ==
                                                    category.id
                                                        ? searchStyleActive
                                                        : searchStyle
                                                }
                                                onClick={() => {
                                                    setSelectedCategory(
                                                        category.id
                                                    );
                                                    window.sessionStorage.setItem(
                                                        "categoryForSearch",
                                                        category.id
                                                    );
                                                }}
                                            >
                                                {category.name}
                                            </div>

                                            {(selectedCategory == category.id ||
                                                productCategories.find(
                                                    (pc) =>
                                                        pc.id ==
                                                        selectedCategory
                                                )?.parent_category_id ==
                                                    category.id) &&
                                                productCategories
                                                    .filter(
                                                        (pc) =>
                                                            pc.parent_category_id ==
                                                                category.id &&
                                                            pc.sub_category_id ==
                                                                0
                                                    )
                                                    .map((category_sub) => (
                                                        <div
                                                            key={
                                                                category_sub.id
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    selectedCategory ==
                                                                    category_sub.id
                                                                        ? searchStyleActive
                                                                        : searchStyle
                                                                }
                                                                onClick={() => {
                                                                    setSelectedCategory(
                                                                        category_sub.id
                                                                    );
                                                                    window.sessionStorage.setItem(
                                                                        "categoryForSearch",
                                                                        category_sub.id
                                                                    );
                                                                }}
                                                            >
                                                                <div className="ml-4">
                                                                    {
                                                                        category_sub.name
                                                                    }
                                                                </div>
                                                            </div>

                                                            {(selectedCategory ==
                                                                category_sub.id ||
                                                                productCategories.find(
                                                                    (pc) =>
                                                                        pc.id ==
                                                                        selectedCategory
                                                                )
                                                                    ?.sub_category_id ==
                                                                    category_sub.id) &&
                                                                productCategories
                                                                    .filter(
                                                                        (pc) =>
                                                                            pc.sub_category_id ==
                                                                            category_sub.id
                                                                    )
                                                                    .map(
                                                                        (
                                                                            category_sub_sub
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    category_sub_sub.id
                                                                                }
                                                                                className={
                                                                                    selectedCategory ==
                                                                                    category_sub_sub.id
                                                                                        ? searchStyleActive
                                                                                        : searchStyle
                                                                                }
                                                                                onClick={() => {
                                                                                    setSelectedCategory(
                                                                                        category_sub_sub.id
                                                                                    );
                                                                                    window.sessionStorage.setItem(
                                                                                        "categoryForSearch",
                                                                                        category_sub_sub.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className="ml-8">
                                                                                    {
                                                                                        category_sub_sub.name
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                        </div>
                                                    ))}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-full">
                    {loading ? (
                        <p className="text-center font-bold">Loading...</p>
                    ) : (
                        <ErrorBoundary>
                            <table
                                {...getTableProps()}
                                className="mb-10 w-full table-auto"
                                style={{
                                    borderCollapse: "separate",
                                    borderSpacing: "0 1em",
                                }}
                            >
                                <thead className="sticky top-32 lg:top-40 md:top-44 sm:top-44 z-10">
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                            className="bg-gray-100 flex flex-col flex-no wrap md:table-row w-full"
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    // py-5
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        className="px-2 font-light"
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="flex-1 sm:flex-none"
                                >
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className="bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row"
                                            >
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            style={{
                                                                // borderRight:
                                                                //     "1px solid #EFEFEF",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                            className="mx-auto text-center relative text-sm px-2 py-2 lg:border-r md:border-r border-slate-300"
                                                        >
                                                            {cell.row.original
                                                                .col12 ===
                                                                "yes" &&
                                                            cell.row.original
                                                                .col4 !=
                                                                "Out of Stock" &&
                                                            imgActive ===
                                                                true &&
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col1" ? (
                                                                <div
                                                                    className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                                    style={{
                                                                        clipPath:
                                                                            "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                                    }}
                                                                >
                                                                    <p className="px-4 py-1.5 text-white font-bold">
                                                                        PROMOTION
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {cell.row.original
                                                                .col12 ===
                                                                "yes" &&
                                                            cell.row.original
                                                                .col4 !=
                                                                "Out of Stock" &&
                                                            imgActive ===
                                                                false &&
                                                            stockActive ===
                                                                true &&
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col2" ? (
                                                                <div
                                                                    className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                                    style={{
                                                                        clipPath:
                                                                            "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                                    }}
                                                                >
                                                                    <p className="px-4 py-1.5 text-white font-bold">
                                                                        PROMOTION
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {cell.row.original
                                                                .col12 ===
                                                                "yes" &&
                                                            cell.row.original
                                                                .col4 !=
                                                                "Out of Stock" &&
                                                            imgActive ===
                                                                false &&
                                                            stockActive ===
                                                                false &&
                                                            barActive ===
                                                                true &&
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col18" ? (
                                                                <div
                                                                    className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                                    style={{
                                                                        clipPath:
                                                                            "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                                    }}
                                                                >
                                                                    <p className="px-4 py-1.5 text-white font-bold">
                                                                        PROMOTION
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {cell.row.original
                                                                .col12 ===
                                                                "yes" &&
                                                            cell.row.original
                                                                .col4 !=
                                                                "Out of Stock" &&
                                                            imgActive ===
                                                                false &&
                                                            stockActive ===
                                                                false &&
                                                            barActive ===
                                                                false &&
                                                            descActive ===
                                                                true &&
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col3" ? (
                                                                <div
                                                                    className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                                    style={{
                                                                        clipPath:
                                                                            "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                                    }}
                                                                >
                                                                    <p className="px-4 py-1.5 text-white font-bold">
                                                                        PROMOTION
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {cell.row.original
                                                                .col12 ===
                                                                "yes" &&
                                                            cell.row.original
                                                                .col4 !=
                                                                "Out of Stock" &&
                                                            imgActive ===
                                                                false &&
                                                            stockActive ===
                                                                false &&
                                                            barActive ===
                                                                false &&
                                                            descActive ===
                                                                false &&
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col4" ? (
                                                                <div
                                                                    className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                                    style={{
                                                                        clipPath:
                                                                            "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                                    }}
                                                                >
                                                                    <p className="px-4 py-1.5 text-white font-bold">
                                                                        PROMOTION
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}

                                                            {cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col2" ||
                                                            cell.render("Cell")
                                                                .props.cell
                                                                .column.id ===
                                                                "col4" ? (
                                                                <p
                                                                    className="text-gray-500 text-2xl lg:text-sm md:text-sm"
                                                                    style={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {cell.render(
                                                                        "Cell"
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {cell.render(
                                                                        "Cell"
                                                                    ).props.cell
                                                                        .column
                                                                        .id ===
                                                                    "col3" ? (
                                                                        <p className="text-center lg:text-left md:text-left">
                                                                            {cell.render(
                                                                                "Cell"
                                                                            )}
                                                                        </p>
                                                                    ) : (
                                                                        cell.render(
                                                                            "Cell"
                                                                        )
                                                                    )}
                                                                </>
                                                                // cell.render("Cell")
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ErrorBoundary>
                    )}

                    <nav
                        className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                        aria-label="Pagination"
                    >
                        <div className="hidden sm:block">
                            <div className="flex">
                                <div>
                                    <select
                                        value={pageSizeValue}
                                        onChange={(e) => {
                                            changePageSize(
                                                e.target.value,
                                                selectedCategory
                                            );
                                            setPageSize(Number(e.target.value));
                                        }}
                                    >
                                        {[10, 25, 50].map((pageSize) => (
                                            <option
                                                key={pageSize}
                                                value={pageSize}
                                            >
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <p className="text-sm text-gray-700 ml-4">
                                        Page{" "}
                                        <span className="font-medium">
                                            {parseFloat(pageNumber) + 1}
                                        </span>{" "}
                                        of{" "}
                                        {SearchValue == "" &&
                                        selectedCategory == "" &&
                                        selectedSupplier == "" ? (
                                            "0"
                                        ) : (
                                            <span className="font-medium">
                                                {Math.ceil(
                                                    parseFloat(recordCount) /
                                                        Number(pageSizeValue)
                                                )}
                                            </span>
                                        )}
                                    </p>
                                    <p className="text-sm text-gray-700 ml-4">
                                        Showing{" "}
                                        <span className="font-medium">
                                            {parseFloat(pageNumber) === 0
                                                ? "1"
                                                : parseFloat(pageNumber) *
                                                      Number(pageSizeValue) +
                                                  1}
                                        </span>{" "}
                                        to{" "}
                                        <span className="font-medium">
                                            {parseFloat(pageNumber) === 0
                                                ? pageSizeValue
                                                : parseFloat(pageNumber) *
                                                      Number(pageSizeValue) +
                                                      Number(pageSizeValue) >
                                                  recordCount
                                                ? recordCount
                                                : parseFloat(pageNumber) *
                                                      Number(pageSizeValue) +
                                                  Number(pageSizeValue)}
                                        </span>{" "}
                                        of{" "}
                                        {SearchValue == "" &&
                                        selectedCategory == "" &&
                                        selectedSupplier == "" ? (
                                            "0"
                                        ) : (
                                            <span className="font-medium">
                                                {recordCount}
                                            </span>
                                        )}{" "}
                                        results
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                            <button
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                onClick={() =>
                                    previousPageNavigate(
                                        pageNumber,
                                        pageSizeValue,
                                        selectedCategory
                                    )
                                }
                                disabled={pageNumber === 0}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() =>
                                    nextPageNavigate(
                                        pageNumber,
                                        pageSizeValue,
                                        selectedCategory
                                    )
                                }
                                disabled={
                                    parseFloat(pageNumber) * 10 + 10 >
                                    recordCount
                                }
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}
