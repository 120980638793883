import { faCaretDown, faCaretUp, faHeart, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy } from 'react-table'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomButton from "../../../../app/common/form/CustomButton";


export default function Orders(props) {

    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);

    const [cartTotal, setCartTotal] = useState([])
    const [orders, setOrders] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [recordCount, setRecordCount] = useState("0");
    const [sortBy, setSortBy] = useState("EntrySystemDate");
    const [sortDirection, setSortDirection] = useState("desc");
    const [pageSizeValue, setPageSizeValue] = useState(10);
    const [filterValue, setFilterValue] = useState("");

    if (currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1)) {
        window.location.replace('/select-profile')
    }

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getOrders(pageSizeValue, filterValue);
    }, [sortBy, sortDirection, filterValue])

    async function getOrders(pageSizeValue, filterItem) {
        let orders = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}all-orders`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                customer_code: currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                pageNumber: pageNumber,
                pageSizeValue: pageSizeValue,
                sortBy: sortBy,
                sortDirection: sortDirection,
                filterItem: filterItem,
            },
        });

        let orderList = orders.data[0];
        setRecordCount(orders.data[1][0][""]);

        let resultOrder = orders.data[2].map(a => a.order_no);
        let ownOrders = orders.data[2];

        // console.log(orderList)

        let allOrders = [];

        await Object.keys(orderList).forEach(async function (key) {

            let statusName = '';

            if (orderList[key].OrderStatus == "1") {
                statusName = "Open Order";
            } else if (orderList[key].OrderStatus == "2") {
                statusName = "Open Back Order"
            } else if (orderList[key].OrderStatus == "3") {
                statusName = "Released back order"
            } else if (orderList[key].OrderStatus == "4") {
                statusName = "In warehouse"
            } else if (orderList[key].OrderStatus == "8") {
                statusName = "To invoice"
            } else if (orderList[key].OrderStatus == "F") {
                statusName = "Forward order"
            } else if (orderList[key].OrderStatus == "S") {
                statusName = "In Suspense"
            } else if (orderList[key].OrderStatus == "9") {
                statusName = "Complete"
            } else if (orderList[key].OrderStatus == "\\") {
                statusName = "Cancelled"
            }

            if (orderList[key].ActiveFlag == "N") {
                var status = "Inactive (" + statusName + ")"
            } else if (orderList[key].CancelledFlag == "Y") {
                var status = "Cancelled (" + statusName + ")"
            } else {
                var status = "(" + statusName + ")"
            }

            let placed_by = "Inhouse";

            if (resultOrder.includes(orderList[key].SalesOrder)) {

                await Object.keys(ownOrders).forEach(function (key2) {
                    if (ownOrders[key2].order_no == orderList[key].SalesOrder) {
                        placed_by = ownOrders[key2].user.is_sales === 1 ? "Rep - " + ownOrders[key2].user.email : "Customer - " + ownOrders[key2].user.email;
                    }
                })
            }

            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            allOrders.push({ col0: orderList[key].SalesOrder, col1: orderList[key].SalesOrder, col2: orderList[key].SalesOrder, col3: orderList[key].EntrySystemDate !== null ? new Date(orderList[key].EntrySystemDate).toLocaleDateString("en-US", options) : "", col4: orderList[key].DateLastDocPrt !== null ? new Date(orderList[key].DateLastDocPrt).toLocaleDateString("en-US", options) : "", col5: status, col6: "R " + orderList[key].orderCost, col7: orderList[key].CustomerPoNumber, col8: placed_by })
        })

        setOrders(allOrders)
    }

    async function changePageSize(value) {
        setPageSizeValue(value)
        getOrders(value);
    }

    const data = React.useMemo(() => orders, [orders])

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="SalesOrder"
                        className="cursor-pointer flex"
                    >
                        <span
                            onClick={() => {
                                setSortBy("SalesOrder");
                                "SalesOrder" == sortBy && sortDirection == "asc"
                                    ? setSortDirection("desc")
                                    : setSortDirection("asc");
                            }}
                        >
                            Order No.
                        </span>

                        {sortBy === "SalesOrder" && sortDirection === "asc" ? (
                            <FontAwesomeIcon
                                icon={faCaretUp}
                                className={
                                    sortBy === "SalesOrder"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("SalesOrder");
                                    "SalesOrder" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={
                                    sortBy === "SalesOrder"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("SalesOrder");
                                    "SalesOrder" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        )}
                        {sortBy === "SalesOrder" && (
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-red-400 ml-5 self-center cursor-pointer"
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    setSortDirection("desc");
                                }}
                            />
                        )}

                    </div>
                ),
                accessor: 'col1',
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="CustomerPoNumber"
                        className="cursor-pointer flex"
                    >

                        <span
                            onClick={() => {
                                setSortBy("CustomerPoNumber");
                                "CustomerPoNumber" == sortBy && sortDirection == "asc"
                                    ? setSortDirection("desc")
                                    : setSortDirection("asc");
                            }}
                        >
                            Product Order Number
                        </span>

                        {sortBy === "CustomerPoNumber" && sortDirection === "asc" ? (
                            <FontAwesomeIcon
                                icon={faCaretUp}
                                className={
                                    sortBy === "CustomerPoNumber"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("CustomerPoNumber");
                                    "CustomerPoNumber" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={
                                    sortBy === "CustomerPoNumber"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("CustomerPoNumber");
                                    "CustomerPoNumber" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        )}
                        {sortBy === "CustomerPoNumber" && (
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-red-400 ml-5 self-center cursor-pointer"
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    setSortDirection("desc");
                                }}
                            />
                        )}
                    </div>),
                accessor: 'col7',
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="EntrySystemDate"
                        className="cursor-pointer flex"
                    >
                        <span
                            onClick={() => {
                                setSortBy("EntrySystemDate");
                                "EntrySystemDate" == sortBy && sortDirection == "asc"
                                    ? setSortDirection("desc")
                                    : setSortDirection("asc");
                            }}
                        >
                            Order Date
                        </span>

                        {sortBy === "EntrySystemDate" && sortDirection === "asc" ? (
                            <FontAwesomeIcon
                                icon={faCaretUp}
                                className={
                                    sortBy === "EntrySystemDate"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    "EntrySystemDate" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={
                                    sortBy === "EntrySystemDate"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    "EntrySystemDate" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        )}
                        {sortBy === "EntrySystemDate" && sortDirection === "asc" && (
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-red-400 ml-5 self-center cursor-pointer"
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    setSortDirection("desc");
                                }}
                            />
                        )}
                    </div>),
                accessor: 'col3',
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                        id="OrderStatus"
                        className="cursor-pointer flex"
                    >
                        <span
                            onClick={() => {
                                setSortBy("OrderStatus");
                                "OrderStatus" == sortBy && sortDirection == "asc"
                                    ? setSortDirection("desc")
                                    : setSortDirection("asc");
                            }}
                        >
                            Order Status
                        </span>

                        {sortBy === "OrderStatus" && sortDirection === "asc" ? (
                            <FontAwesomeIcon
                                icon={faCaretUp}
                                className={
                                    sortBy === "OrderStatus"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("OrderStatus");
                                    "OrderStatus" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className={
                                    sortBy === "OrderStatus"
                                        ? "text-blue-600 ml-5 self-center"
                                        : "text-gray-400 ml-5 self-center"
                                }
                                onClick={() => {
                                    setSortBy("OrderStatus");
                                    "OrderStatus" == sortBy && sortDirection == "asc"
                                        ? setSortDirection("desc")
                                        : setSortDirection("asc");
                                }}
                            />
                        )}
                        {sortBy === "OrderStatus" && (
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="text-red-400 ml-5 self-center cursor-pointer"
                                onClick={() => {
                                    setSortBy("EntrySystemDate");
                                    setSortDirection("desc");
                                }}
                            />
                        )}
                    </div>),
                accessor: 'col5',
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Placed By</div>),
                accessor: 'col8',
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left"
                        }}
                    >Total</div>),
                accessor: 'col6',
            },

        ],
        [sortBy, sortDirection]
    )

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageIndex,
        pageOptions,
        gotoPage,
        pageCount,
        pageSize,
        setPageSize,
    } = tableInstance

    async function nextPageNavigate(pageNumber, pageSizeValue) {

        let pageNo = parseFloat(pageNumber)
        let newNo = pageNo + 1

        setPageNumber(newNo)

        getOrders(pageSizeValue);
    }

    async function previousPageNavigate(pageNumber, pageSizeValue) {

        let pageNo = parseFloat(pageNumber)
        let newNo = pageNo - 1

        setPageNumber(newNo)

        getOrders(pageSizeValue);
    }

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Orders"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Orders", href: "/orders", count: 2 },
                ]}
            />

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br /><br />

            <div className="container mx-auto relative pt-20">
                <div className="flex absolute top-0 right-0 mt-5">
                    <Link
                        as="a"
                        to={"/backorders"}
                        className="hover:text-lk_dark_grey_button_hover"
                    >
                        <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_button_hover uppercase mx-auto">
                            View all items on Backorder
                        </button>
                    </Link>
                </div>
                <label className="mt-2 mr-2">Filter: </label><input type="text" onChange={(v) => setFilterValue(v.target.value)} />
                <br />
                <small>When filtering by date please enter in YYYY-MM-DD format</small>
            </div>

            <div className="container mx-auto">

                <table {...getTableProps()} className="mb-10 w-full" style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                    <thead>
                        {
                            headerGroups.map((headerGroup, index) => (

                                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-200" key={index}>
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-2 py-5 font-light text-sm">
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {

                                prepareRow(row)
                                return (


                                    <tr {...row.getRowProps()} className="bg-white mt-5 shadow-lg cursor-pointer" onClick={() => history.push("/order/" + row.original.col0)}>
                                        {
                                            row.cells.map((cell, index) => {

                                                return (
                                                    <td {...cell.getCellProps()} style={{ borderRight: "1px solid #EFEFEF", textAlign: "left" }} className="mx-auto text-center relative px-2 py-2" key={index}>
                                                        <p className="text-center px-5 py-5">{cell.render('Cell')}</p>
                                                    </td>
                                                )
                                            })}
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>

                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                        <div className="flex">
                            <div>
                                <select value={pageSizeValue} onChange={e => { changePageSize(e.target.value); setPageSize(Number(e.target.value)) }} >
                                    {
                                        [10, 25, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-gray-700">
                                    Page <span className="font-medium">{parseFloat(pageNumber) + 1}</span> of{" "}
                                    <span className="font-medium">{Math.ceil(parseFloat(recordCount) / Number(pageSizeValue))}</span>
                                </p>
                                <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{parseFloat(pageNumber) === 0 ? "1" : (parseFloat(pageNumber) * Number(pageSizeValue)) + 1}</span> to{" "}
                                    <span className="font-medium">{parseFloat(pageNumber) === 0 ? pageSizeValue : (parseFloat(pageNumber) * Number(pageSizeValue)) + Number(pageSizeValue) > recordCount ? recordCount : (parseFloat(pageNumber) * Number(pageSizeValue)) + Number(pageSizeValue)}</span> of{" "}
                                    <span className="font-medium">{recordCount}</span> results
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => previousPageNavigate(pageNumber, pageSizeValue)}
                            disabled={pageNumber === 0}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => nextPageNavigate(pageNumber, pageSizeValue)}
                            disabled={(parseFloat(pageNumber) * 10) + 10 > recordCount}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>

            </div>

        </div>
    )
}